import Glide, { Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'

document.addEventListener('turbo:load', () => {
  if(document.querySelector('.glide__slide')) {
    const glide = new Glide('.glide', {
      type: 'slider',
      bound: true,
      focusAt: 0, // Slide to focus on
      startAt: 0, // Starting slide index
      perView: 4, // Number of slides to show
      breakpoints: {
        768: {
          perView: 1
        },
        1200: {
          perView: 3
        },
      },
      rewind: false, // Rewind to the first slide upon reaching the end
    })
    glide.on('mount.before', () => {
      const calendarWrapper = document.querySelector('.home-calendar-wrapper')
      if(calendarWrapper) {
        calendarWrapper.classList.add('start')
      }
    }),

    glide.on('run', () => {
      // Check if the slider is at the first slide
      const isFirstSlide = glide.index === 0
      const isLastSlide = glide.index === document.querySelectorAll('.glide__slide').length - glide._o.perView
      const nextButton = document.querySelector('.glide__arrow--right')
      const prevButton = document.querySelector('.glide__arrow--left')
      const calendarWrapper = document.querySelector('.home-calendar-wrapper')
      if (prevButton) {
        if (isFirstSlide) {
          if(calendarWrapper) {
            calendarWrapper.classList.add('start')
            calendarWrapper.classList.remove('scrolled')
            calendarWrapper.classList.remove('end')
          }
          prevButton.classList.add('disabled')
          prevButton.disabled = true
        } else {
          if(calendarWrapper) {
            calendarWrapper.classList.remove('start')
            calendarWrapper.classList.add('scrolled')
            calendarWrapper.classList.remove('end')
          }
          prevButton.classList.remove('disabled')
          prevButton.disabled = false
        }
      }

      if (nextButton) {
        if (isLastSlide) {
          if(calendarWrapper) {
            calendarWrapper.classList.add('end')
          }
          nextButton.classList.add('disabled')
          nextButton.disabled = true
        } else {
          nextButton.classList.remove('disabled')
          nextButton.disabled = false
        }
      }
    })

    glide.mount({ Breakpoints }) // Mount the carousel

    document.querySelector('.glide__arrow--left').addEventListener('click', () => {
      glide.go('<') // Go to the previous slide
    })

    document.querySelector('.glide__arrow--right').addEventListener('click', () => {
      glide.go('>') // Go to the next slide
    })
  }
})